import React, { useContext } from 'react'
import {
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Box,
  Text,
  Icon,
  Avatar,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import ReactQuill from 'react-quill'
import ModalContext from '../../../store/modal-context'
import NotesContext from '../../../store/notes-context'
import CategoriesContext from '../../../store/categories-context'
import { Formik, Form, Field } from 'formik'
import { MdAdd } from 'react-icons/md'
import TagsContext from '../../../store/tags-context'
import { ICONS } from '../../../utils/icons'
import CharactersContext from '../../../store/characters-context'
import ProjectsContext from '../../../store/projects-context'

interface EditNoteModalProps {
  meta?: any
}

const EditNoteModal: React.FC<EditNoteModalProps> = ({ meta }) => {
  const note = meta?.model || {}
  const category = meta?.category
  const board = meta?.board

  const modalCtx = useContext(ModalContext)
  const { popModal, pushModal } = modalCtx
  const noteCtx = useContext(NotesContext)
  const { createNote, updateNote } = noteCtx
  const projectCtx = useContext(ProjectsContext)
  const { project } = projectCtx
  const categoryCtx = useContext(CategoriesContext)
  const { categories } = categoryCtx
  const tagsCtx = useContext(TagsContext)
  const { tags } = tagsCtx
  const characterCtx = useContext(CharactersContext)
  const { characters } = characterCtx

  const validateSummary = (value: string) => {
    let error
    if (!value) {
      error = 'Summary is required'
    }
    return error
  }

  const validateCategory = (value: number) => {
    let error
    if (!value) {
      error = 'Category is required'
    }
    return error
  }

  const handleSaveNote = (
    values: {
      summary: string
      content: string
      categoryId: number
      tagIds: number[]
      characterIds: number[]
    },
    actions: any,
  ) => {
    const noteData: {
      summary: string
      content: string
      category_id: number
      tag_ids: number[]
      character_ids: number[]
      id: number | null
      position: any
      board_id: number | null
      model_type?: string
      tags?: any[]
      characters?: any[]
    } = {
      summary: values.summary,
      content: values.content,
      category_id: values.categoryId,
      tag_ids: values.tagIds,
      character_ids: values.characterIds,
      id: note?.id || null,
      position: note?.position || null,
      board_id: note?.board_id || board?.id || null,
    }

    if (project?.id === 'example') {
      noteData.model_type = 'Note'
      // @ts-ignore - this is a mock project
      noteData.tags = tags.filter((t) => values.tagIds.includes(t.id))
      noteData.characters = characters.filter((c) =>
        // @ts-ignore - this is a mock project
        values.characterIds.includes(c.id),
      )
    }

    const saveFn = note?.id ? updateNote : createNote

    saveFn(noteData)
      .then(() => {
        popModal()
      })
      .catch((error) => {
        console.error('Error saving note:', error)
        actions.setSubmitting(false)
      })
  }

  const categoryOptions = categories.map((c) => ({
    value: c.id,
    label: (
      <Flex alignItems="center">
        <Box w={4} h={4} bg={c.color} mr={2} borderRadius="md" />
        {c.name}
      </Flex>
    ),
  }))
  categoryOptions.push({
    value: 0,
    label: (
      <Flex
        alignItems="center"
        justify="center"
        flex={1}
        onClick={() => {
          pushModal({
            modal: 'CategoryModal',
            previousModalState: { meta: { model: note } },
          })
        }}
      >
        <MdAdd /> <Text ml={2}>New Plotline</Text>
      </Flex>
    ),
  })

  const noteTagIds = note?.tags?.map((t: any) => t.id) || []
  const tagOptions = tags.map((t) => ({
    value: t.id,
    label: (
      <Flex alignItems="center">
        <Icon as={ICONS[t.icon]} w={4} h={4} mr={2} color={t.color} />
        {t.name}
      </Flex>
    ),
  }))
  tagOptions.push({
    value: 0,
    label: (
      <Flex
        alignItems="center"
        justify="center"
        flex={1}
        onClick={() => {
          pushModal({ modal: 'TagModal', previousModalState: { model: note } })
        }}
      >
        <MdAdd /> <Text ml={2}>New Plot Link</Text>
      </Flex>
    ),
  })

  const noteCharacterIds = note?.characters?.map((t: any) => t.id) || []
  const characterOptions = characters.map((c) => ({
    value: c.id,
    label: (
      <Flex alignItems="center">
        <Avatar name={c.name} src={c.avatar_url} size="sm" />
        {c.name}
      </Flex>
    ),
  }))
  characterOptions.push({
    value: 0,
    label: (
      <Flex
        alignItems="center"
        justify="center"
        flex={1}
        onClick={() => {
          pushModal({
            modal: 'EditCharacterModal',
            previousModalState: { model: note },
          })
        }}
      >
        <MdAdd /> <Text ml={2}>New Character</Text>
      </Flex>
    ),
  })

  const contentTemplate = `
    <p>What's the purpose of this scene?</p>
    <p>What essential dialogue belongs here?</p>
    <p>What info or foreshadowing can be woven in?</p>
  `

  return (
    <>
      <ModalHeader>{note?.id ? 'Update' : 'Create'} a Scene</ModalHeader>
      <ModalCloseButton />
      <Formik
        initialValues={{
          categoryId: note?.category_id || category?.id || '',
          summary: note?.summary || '',
          content: note?.content || contentTemplate,
          tagIds: note?.tags?.map((t: any) => t.id) || [],
          characterIds: note?.characters?.map((c: any) => c.id) || [],
        }}
        onSubmit={handleSaveNote}
      >
        {(props) => (
          <Form>
            <ModalBody>
              <Field name="categoryId" validate={validateCategory}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl mb={4} isInvalid={form.errors.categoryId}>
                    <FormLabel>Plotline</FormLabel>
                    <Select
                      options={categoryOptions}
                      defaultValue={categoryOptions.find(
                        (c) => c.value === (note?.category_id || category?.id),
                      )}
                      onChange={(selected) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: selected?.value,
                          },
                        })
                        note.category_id = selected?.value
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.categoryId}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="summary" validate={validateSummary}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.summary && form.touched.summary}
                  >
                    <FormLabel>Summary</FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter note summary"
                      {...field}
                      onChange={(e) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: e.target.value,
                          },
                        })
                        note.summary = e.target.value
                      }}
                    />
                    <FormErrorMessage>{form.errors.summary}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="tagIds">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl my={4}>
                    <FormLabel>Plot Links</FormLabel>
                    <Select
                      isMulti
                      options={tagOptions}
                      defaultValue={tagOptions.filter((c) =>
                        noteTagIds.includes(c.value),
                      )}
                      onChange={(selected, meta) => {
                        if (meta?.option?.value === 0) {
                          if (Array.isArray(selected)) {
                            selected.pop()
                          }
                          return
                        }

                        field.onChange({
                          target: {
                            name: field.name,
                            value: selected?.map((s: any) => s.value) || [],
                          },
                        })

                        note.tags = selected?.map((s: any) => {
                          return {
                            id: s.value,
                          }
                        })
                      }}
                    />
                    <FormErrorMessage>{form.errors.tagIds}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="characterIds">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl my={4}>
                    <FormLabel>Characters</FormLabel>
                    <Select
                      isMulti
                      options={characterOptions}
                      defaultValue={characterOptions.filter((c) =>
                        noteCharacterIds.includes(c.value),
                      )}
                      onChange={(selected, meta) => {
                        if (meta?.option?.value === 0) {
                          if (Array.isArray(selected)) {
                            selected.pop()
                          }
                          return
                        }

                        field.onChange({
                          target: {
                            name: field.name,
                            value: selected?.map((s: any) => s.value) || [],
                          },
                        })

                        note.characters = selected?.map((s: any) => {
                          return {
                            id: s.value,
                          }
                        })
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.characterIds}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="content">
                {({ field }: { field: any }) => (
                  <FormControl my={4}>
                    <FormLabel>Content</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={field.value}
                      onChange={(content) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: content,
                          },
                        })
                        note.content = content
                      }}
                    />
                  </FormControl>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={props.isSubmitting}
                colorScheme="teal"
                mr={3}
                type="submit"
              >
                Save
              </Button>
              <Button
                isDisabled={props.isSubmitting}
                variant="outline"
                mr={3}
                onClick={() => {
                  popModal()
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EditNoteModal
