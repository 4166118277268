import React, { ReactNode, createContext, useContext } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'
import ProjectsContext from './projects-context'
import BoardsContext from './boards-context'

// Define the shape of a note
export interface NoteSchema {
  id: number | null
  summary: string
  content?: string
  position?: any
  board_id: number | null
  category_id?: number
  tags?: any[]
  tag_ids?: number[]
  characters?: any[]
  character_ids?: number[]
  model_type?: string
}

// Define the shape of the context
interface NotesContextType {
  getNotes: (boardId: NoteSchema['board_id']) => Promise<AxiosResponse>
  createNote: (note: NoteSchema) => Promise<AxiosResponse>
  updateNote: (note: NoteSchema) => Promise<AxiosResponse>
  deleteNote: (id: NoteSchema['id']) => Promise<AxiosResponse>
}

// Create the initial context
const NotesContext = createContext<NotesContextType>({
  getNotes: async () => {
    throw new Error('getNote function not implemented')
  },
  createNote: async () => {
    throw new Error('createNote function not implemented')
  },
  updateNote: async () => {
    throw new Error('updateNote function not implemented')
  },
  deleteNote: async () => {
    throw new Error('deleteNote function not implemented')
  },
})

interface NotesProviderProps {
  children?: ReactNode
}

// Create the provider component
export const NotesProvider: React.FC<NotesProviderProps> = ({ children }) => {
  const projectCtx = useContext(ProjectsContext)
  const { project } = projectCtx
  const boardsCtx = useContext(BoardsContext)
  const { boards, ideationBoard, setBoards, setIdeationBoard } = boardsCtx

  const getNotes = async (boardId: NoteSchema['board_id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .get('api/v1/notes', {
          params: { board_id: boardId },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const createNote = async (note: NoteSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project && project.id === 'example') {
        const newNote = {
          ...note,
          id: Math.floor(Math.random() * 1000000),
          model_type: 'Note',
        }
        handleCreateNoteCallback(newNote)
        resolve({
          data: {
            notes: newNote,
          },
        } as AxiosResponse)
      } else {
        axios
          .post('api/v1/notes', { note: note })
          .then((response) => {
            handleCreateNoteCallback(response.data.notes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const handleCreateNoteCallback = (newNote: NoteSchema) => {
    if (ideationBoard?.notes && newNote.board_id === ideationBoard.id) {
      ideationBoard.notes.push(newNote)
      setIdeationBoard(ideationBoard)
    } else {
      const board = boards.find((b) => b.id === newNote.board_id)
      if (board?.notes) {
        board.notes.push(newNote)
        setBoards([...boards])
      }
    }
  }

  const updateNote = (note: NoteSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project && project.id === 'example') {
        handleUpdateNoteCallback(note)
        resolve({ data: { notes: note } } as AxiosResponse)
      } else {
        axios
          .put(`api/v1/notes/${note.id}`, { note: note })
          .then((response) => {
            handleUpdateNoteCallback(response.data.notes)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const handleUpdateNoteCallback = (updatedNote: NoteSchema) => {
    const isOnIdeationBoard = updatedNote.board_id === ideationBoard?.id
    const board = isOnIdeationBoard
      ? ideationBoard
      : boards.find((b) => b.id === updatedNote.board_id)

    if (board?.notes) {
      board.notes = board.notes.map((n) =>
        n.id === updatedNote.id ? updatedNote : n,
      )

      isOnIdeationBoard ? setIdeationBoard(board) : setBoards([...boards])
    }
  }

  const deleteNote = (id: NoteSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project && project.id === 'example') {
        resolve({ data: {} } as AxiosResponse)
      } else {
        axios
          .delete(`api/v1/notes/${id}`)
          .then((response) => {
            // THere is a callback in ConfirmationModal.tsx that will
            // remove the note from the board and update the boards state.
            // In an ideal world, this would be handled here. But this can't
            // be done without refactoring.
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  return (
    <NotesContext.Provider
      value={{
        getNotes,
        createNote,
        updateNote,
        deleteNote,
      }}
    >
      {children}
    </NotesContext.Provider>
  )
}

// Custom hook to access the notes context
export default NotesContext
