import React, { useEffect, useState, useContext } from 'react'
import MainLayout from '../layouts/MainLayout'
import {
  Stack,
  Center,
  Spinner,
  useMediaQuery,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Flex,
} from '@chakra-ui/react'
import SidebarExtension from '../components/side-bar/SidebarExtension'
import Sidebar from '../components/side-bar/Sidebar2'
import { useParams } from 'react-router-dom'
import ProjectsContext from '../store/projects-context'
import BoardsContext from '../store/boards-context'
import { useNavigate } from 'react-router-dom'
import KanbanBoard from '../components/kanban-board/KanbanBoard'
import { DragDropContext } from '@hello-pangea/dnd'
import NotesContext from '../store/notes-context'
import DragContext from '../store/drag-context'
import TagsContext from '../store/tags-context'
import CategoriesContext from '../store/categories-context'
import CharactersContext from '../store/characters-context'

// These tabs are used to determine which tab is selected in the Sidebar component
export const tabs = {
  NONE: 0,
  CATEGORIES: 1,
  TAGS: 2,
  CAST: 3,
}

const ProjectShowPage: React.FC = () => {
  const projectsCtx = useContext(ProjectsContext)
  const dragCtx = useContext(DragContext)
  const notesCxt = useContext(NotesContext)
  const { updateNote } = notesCxt
  const tagsCtx = useContext(TagsContext)
  const { getTags } = tagsCtx
  const charactersCtx = useContext(CharactersContext)
  const { getCharacters } = charactersCtx
  const categoriesCtx = useContext(CategoriesContext)
  const { getCategories } = categoriesCtx
  const boardsCtx = useContext(BoardsContext)
  const {
    boards,
    ideationBoard,
    setIdeationBoard,
    setBoards,
    createBoard,
    updateBoard,
  } = boardsCtx
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [initializing, setInitializing] = useState(true)
  const [tabSelection, setTabSelection] = useState(tabs.NONE)
  const [mdAndUp] = useMediaQuery('(min-width: 48em)')

  const parsedProjectid =
    projectId === 'example' ? 'example' : Number(projectId)

  useEffect(() => {
    projectsCtx
      .getProject(parsedProjectid)
      .then((res) => {
        setIdeationBoard(res?.data?.projects?.ideation_board)
        getCategories(parsedProjectid)
        getTags(parsedProjectid)
        getCharacters(parsedProjectid)
        setInitializing(false)
      })
      .catch((e) => {
        console.error(e)
        navigate('/projects')
      })

    // eslint-disable-next-line
  }, [])

  const handleDragStart = () => {
    dragCtx.setDragging(true)
  }

  const handleDragEnd = async (result: any) => {
    const { destination, source, type } = result

    if (!destination) {
      dragCtx.setDragging(false)
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      dragCtx.setDragging(false)
      return
    }

    if (type === 'board') {
      handleBoardDragged(result)
    } else if (type === 'note') {
      // Note was dragged to create a new board
      if (destination.droppableId.match(/newBoard-[0-9]/)) {
        handleNoteDraggedToNewBoard(result)
      } else {
        const newBoardId = destination.droppableId.match(/board-[0-9]/)
          ? +destination.droppableId.split('-')[1]
          : ideationBoard?.id
        const prevBoardId = source.droppableId.match(/board-[0-9]/)
          ? +source.droppableId.split('-')[1]
          : ideationBoard?.id

        // Note was dragged to the same board
        if (newBoardId && newBoardId === prevBoardId) {
          handleNoteDraggedToSameBoard(result, newBoardId)
        }
        // Note was dragged to a new board
        else if (newBoardId && prevBoardId && newBoardId !== prevBoardId) {
          handleNoteDraggedToDifferentBoard(result, newBoardId, prevBoardId)
        }
      }

      // Reset the dragging state
      dragCtx.setDragging(false)
    }
  }

  const handleBoardDragged = (result: any) => {
    const { destination, source } = result

    const newBoards = [...boards]
    const [movedBoard] = newBoards.splice(source.index, 1)
    newBoards.splice(destination.index, 0, movedBoard)
    setBoards(newBoards)

    const newPosition: { after?: number | null } = {}
    newPosition['after'] =
      destination.index === 0 ? null : newBoards[destination.index - 1].id
    movedBoard.position = newPosition
    updateBoard(movedBoard)
  }

  const handleNoteDraggedToNewBoard = (result: any) => {
    const { destination, source, draggableId } = result

    // Get note id, previous board id, and new board id
    const noteId = +draggableId.split('-')[1]
    const prevBoardId = source.droppableId.match(/board-[0-9]/)
      ? +source.droppableId.split('-')[1]
      : ideationBoard?.id
    const boardId = +destination.droppableId.split('-')[1]

    // Find the previous board
    let prevBoard =
      boards.find((board) => board.id === prevBoardId) || ideationBoard

    // Create a new board and move the note to it
    const newBoardArgs: {
      id: number | null
      name: string
      project_id: string | number
      position: { after: number | null }
      note_ids: number[]
      notes?: any[]
    } = {
      id: null,
      name: '',
      project_id: parsedProjectid,
      position: {
        after: boardId === 0 ? null : boardId,
      },
      note_ids: [noteId],
    }

    // Notes are serialized on the return of the new board. So when working
    // with the example project, we need to add the note to the new board to
    // simulate the same behavior.
    if (projectId === 'example' && prevBoard?.notes) {
      const note = prevBoard.notes.find((note) => note.id === noteId)
      newBoardArgs['notes'] = note ? [note] : []
    }

    // Remove the note from the previous board
    if (prevBoard?.notes) {
      prevBoard.notes = prevBoard.notes.filter((note) => note.id !== noteId)
    }

    createBoard(newBoardArgs)
  }

  const handleNoteDraggedToSameBoard = (
    result: any,
    boardId: number | null,
  ) => {
    const { destination, source, draggableId } = result

    const newBoards = [...boards]
    const noteId = +draggableId.split('-')[1]

    const board = newBoards.find((board) => board.id === boardId)
    const movedNote = board?.notes?.find((note) => note.id === noteId)

    if (movedNote && board?.notes) {
      board.notes.splice(source.index, 1)
      board.notes.splice(destination.index, 0, movedNote)

      setBoards(newBoards)

      movedNote.position = {
        after:
          destination.index === 0
            ? null
            : board.notes[destination.index - 1].id,
      }
      updateNote(movedNote)
    }
  }

  const handleNoteDraggedToDifferentBoard = (
    result: any,
    newBoardId: number | null,
    prevBoardId: number | null,
  ) => {
    const { destination, draggableId } = result

    const newBoards = [...boards]
    const noteId = +draggableId.split('-')[1]

    const prevBoard =
      newBoards.find((board) => board.id === prevBoardId) || ideationBoard
    const newBoard =
      newBoards.find((board) => board.id === newBoardId) || ideationBoard
    const movedNote = prevBoard?.notes?.find((note) => note.id === noteId)

    if (movedNote && prevBoard?.notes && newBoard?.notes) {
      prevBoard.notes = prevBoard.notes.filter((note) => note.id !== noteId)
      newBoard.notes.splice(destination.index, 0, movedNote)

      setBoards(newBoards)

      if (prevBoard.id === ideationBoard?.id) {
        setIdeationBoard(prevBoard)
      } else if (newBoard.id === ideationBoard?.id) {
        setIdeationBoard(newBoard)
      }

      movedNote.position = {
        after:
          destination.index === 0
            ? null
            : newBoard.notes[destination.index - 1].id,
      }
      if (newBoardId !== undefined) {
        movedNote.board_id = newBoardId
      }
      updateNote(movedNote)
    }
  }

  if (initializing) {
    return (
      <Center h={'100vh'}>
        <Spinner color="teal" thickness="4px" h={'100px'} w={'100px'} />
      </Center>
    )
  }

  return (
    <MainLayout>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Stack
          direction={mdAndUp ? 'row' : 'column'}
          spacing={0}
          minH="inherit"
          maxH="inherit"
        >
          <Sidebar
            tabSelection={tabSelection}
            setTabSelection={setTabSelection}
          />

          {tabSelection && (
            <>
              {mdAndUp && <SidebarExtension tabSelection={tabSelection} />}
              {!mdAndUp && (
                <>
                  <Drawer
                    isOpen={tabSelection !== tabs.NONE}
                    placement="left"
                    onClose={() => setTabSelection(tabs.NONE)}
                  >
                    {!dragCtx.dragging && <DrawerOverlay />}
                    <DrawerContent
                      bgColor="gray.100"
                      maxW={dragCtx.dragging ? '0%' : '90%'}
                    >
                      <DrawerBody
                        pos={dragCtx.dragging ? 'absolute' : 'unset'}
                        right={dragCtx.dragging ? '0' : 'unset'}
                        px={0}
                      >
                        <Flex justify="center">
                          <SidebarExtension tabSelection={tabSelection} />
                        </Flex>
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </>
              )}
            </>
          )}

          <KanbanBoard />
        </Stack>
      </DragDropContext>
    </MainLayout>
  )
}

export default ProjectShowPage
