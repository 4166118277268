import React, { createContext, useState, ReactNode } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'
import { mockProject } from '../store/mocks/lotr'

// Define the project type
export interface ProjectSchema {
  id: number | string | null
  name: string
}

// Define the context type
interface ProjectsContextType {
  project: ProjectSchema | null
  projects: ProjectSchema[]
  getProject: (id: ProjectSchema['id']) => Promise<AxiosResponse>
  getProjects: () => Promise<AxiosResponse>
  createProject: (project: ProjectSchema) => Promise<AxiosResponse>
  updateProject: (project: ProjectSchema) => Promise<AxiosResponse>
  deleteProject: (id: ProjectSchema['id']) => Promise<AxiosResponse>
  resetProject: () => void
}

// Create the projects context
const ProjectsContext = createContext<ProjectsContextType>({
  project: null,
  projects: [],
  getProject: async () => {
    throw new Error('getProject function not implemented')
  },
  getProjects: async () => {
    throw new Error('getProjects function not implemented')
  },
  createProject: async () => {
    throw new Error('createProject function not implemented')
  },
  updateProject: async () => {
    throw new Error('updateProject function not implemented')
  },
  deleteProject: async () => {
    throw new Error('deleteProject function not implemented')
  },
  resetProject: async () => {
    throw new Error('resetProject function not implemented')
  },
})

// Define the props type for the ProjectsProvider component
interface ProjectsProviderProps {
  children?: ReactNode
}

// Create the projects provider component
export const ProjectsProvider: React.FC<ProjectsProviderProps> = ({
  children,
}) => {
  const [project, setProject] = useState<ProjectSchema | null>(null)
  const [projects, setProjects] = useState<ProjectSchema[]>([])

  const getProject = async (id: ProjectSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (id === 'example') {
        setProject(mockProject)
        resolve({ data: { projects: mockProject } } as AxiosResponse)
      } else {
        axios
          .get(`/api/v1/projects/${id}`)
          .then((res) => {
            setProject(res.data.projects)
            resolve(res)
          })
          .catch((e) => {
            reject(e)
          })
      }
    })
  }

  const getProjects = async () => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .get('/api/v1/projects')
        .then((res) => {
          setProjects(res.data.projects)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  const createProject = async (project: ProjectSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .post('/api/v1/projects', project)
        .then((res) => {
          setProject(res.data.projects)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  // Update an existing project
  const updateProject = (project: ProjectSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project.id === 'example') {
        setProject(project)
        resolve({ data: { projects: project } } as AxiosResponse)
      } else {
        axios
          .put(`/api/v1/projects/${project.id}`, project)
          .then((res) => {
            setProject(res.data.projects)
            resolve(res)
          })
          .catch((e) => {
            reject(e)
          })
      }
    })
  }

  // Delete a project
  const deleteProject = (id: ProjectSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .delete(`/api/v1/projects/${id}`)
        .then((res) => {
          const updatedProjects = projects.filter((p) => p.id !== id)
          setProjects(updatedProjects)
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  const resetProject = () => {
    setProject(null)
  }

  return (
    <ProjectsContext.Provider
      value={{
        project,
        projects,
        getProject,
        getProjects,
        createProject,
        updateProject,
        deleteProject,
        resetProject,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsContext
