import {
  Center,
  Card,
  CardBody,
  Text,
  Flex,
  Icon,
  Grid,
  GridItem,
  Spinner,
  Heading,
  Stack,
  Button,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import ProjectsContext from '../../store/projects-context'
import ProjectGridItem from './ProjectGridtem'
import { useNavigate } from 'react-router-dom'

import styles from './ProjectList.module.css'
import { get } from 'lodash'

const ProjectList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const ctx = useContext(ProjectsContext)
  const projects = ctx.projects
  const navigate = useNavigate()

  useEffect(() => {
    ctx.getProjects().finally(() => {
      setIsLoading(false)
    })

    // eslint-disable-next-line
  }, [])

  const handleCreateProject = () => {
    setIsLoading(true)
    ctx
      .createProject({
        id: null,
        name: 'Untitled Project',
      })
      .then((res) => {
        const projectId = get(res, 'data.projects.id')
        navigate(`/projects/${projectId}`)
      })
      .catch((e) => {
        setIsLoading(false)
        console.error(e)
      })
  }

  if (isLoading) {
    return (
      <Center>
        <Spinner color="teal" thickness="4px" mt={12} h={'100px'} w={'100px'} />
      </Center>
    )
  }

  return (
    <Stack
      mb={12}
      bg="#fafafa"
      p={{ base: 2, md: 6 }}
      mx={{ base: 2, md: 0 }}
      borderRadius="4px"
    >
      <Heading as="h3" size="md" mb={4} maxW="800px">
        Projects
      </Heading>

      <Grid
        templateColumns={{
          base: `repeat(2, 1fr)`,
          md: `repeat(3, 1fr)`,
          lg: `repeat(5, 1fr)`,
        }}
        maxW="800px"
        gap={2}
      >
        <GridItem>
          <Flex direction="column" align="center">
            <Card
              w={146}
              h={186}
              className={styles.card}
              variant="outline"
              onClick={handleCreateProject}
            >
              <CardBody>
                <Flex justify="center" align="center" h="100%">
                  <Icon color="teal" as={IoMdAdd} width={50} height={50} />
                </Flex>
              </CardBody>
            </Card>
            <Text my={2} as="i">
              New Project
            </Text>
          </Flex>
        </GridItem>

        {projects.length > 0 &&
          projects.map((project, i) => (
            <ProjectGridItem
              key={i}
              project={project}
              setIsLoading={setIsLoading}
            />
          ))}
      </Grid>

      <Text mt={4} fontSize="sm">
        Not sure where to start? View an{' '}
        <Button variant="link" onClick={() => navigate(`/projects/example`)}>
          Example project
        </Button>
      </Text>
    </Stack>
  )
}

export default ProjectList
