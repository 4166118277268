import React, { useContext, useState, useEffect } from 'react'
import BoardsContext from '../../store/boards-context'
import ProjectsContext from '../../store/projects-context'
import {
  Container,
  HStack,
  Button,
  Stack,
  Heading,
  Center,
  Spinner,
  Flex,
} from '@chakra-ui/react'
import Column from './Column'
import { MdAdd } from 'react-icons/md'
import ModalContext from '../../store/modal-context'
import { Droppable } from '@hello-pangea/dnd'
import NewBoardDropZone from './NewBoardDropZone'

const KanbanBoard: React.FC = () => {
  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx
  const projectsCtx = useContext(ProjectsContext)
  const { project } = projectsCtx
  const boardsCtx = useContext(BoardsContext)
  const { boards, getBoards } = boardsCtx

  const [initializing, setInitializing] = useState<boolean>(true)

  useEffect(() => {
    if (project) {
      getBoards(project.id).finally(() => {
        setInitializing(false)
      })
    }

    // eslint-disable-next-line
  }, [project])

  if (initializing) {
    return (
      <Center minH="100%" w="100%">
        <Spinner color="teal" thickness="4px" h="100px" w="100px" />
      </Center>
    )
  }

  return (
    <Droppable droppableId="boards" type="board" direction="horizontal">
      {(provided) => (
        <Container
          id="kanban-board"
          overflow="scroll"
          maxW="100%"
          mr={4}
          p={0}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <HStack
            spacing={0}
            align="flex-start"
            justifyContent="flex-start"
            minH="100%"
          >
            <Flex direction="row" ml={2}>
              <NewBoardDropZone boardId={0} />

              {boards.map((board, index) => (
                <Flex key={index}>
                  <Column board={board} index={index} />
                </Flex>
              ))}
            </Flex>

            {provided.placeholder}

            <Stack
              w="240px"
              minW="240px"
              maxW="240px"
              mx={2}
              my={4}
              borderRadius="4px"
            >
              <Button
                borderRadius="4px"
                leftIcon={<MdAdd />}
                variant="outline"
                onClick={() => {
                  openModal({ modal: 'BoardModal' })
                }}
              >
                <Heading size="sm" as={'b'}>
                  New Storyboard
                </Heading>
              </Button>
            </Stack>
          </HStack>
        </Container>
      )}
    </Droppable>
  )
}

export default KanbanBoard
