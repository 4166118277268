import React, { ReactNode, createContext, useContext, useState } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'
import { mockTags } from './mocks/lotr'
import ProjectsContext from './projects-context'

// Define the shape of a tag
export interface TagSchema {
  id: number | null
  name: string
  color: string
  icon: string
  project_id: number | string | null
  model_type?: string
}

// Define the shape of the context
interface TagsContextType {
  tags: TagSchema[]
  tagsLoading: boolean
  getTags: (projectId: TagSchema['project_id']) => Promise<AxiosResponse>
  createTag: (tag: TagSchema) => Promise<AxiosResponse>
  updateTag: (tag: TagSchema) => Promise<AxiosResponse>
  deleteTag: (id: TagSchema['id']) => Promise<AxiosResponse>
}

// Create the initial context
const TagsContext = createContext<TagsContextType>({
  tags: [],
  tagsLoading: false,
  getTags: async () => {
    throw new Error('getTags function not implemented')
  },
  createTag: async () => {
    throw new Error('createTag function not implemented')
  },
  updateTag: async () => {
    throw new Error('updateTag function not implemented')
  },
  deleteTag: async () => {
    throw new Error('deleteTag function not implemented')
  },
})

interface TagsProviderProps {
  children?: ReactNode
}

// Create the provider component
export const TagsProvider: React.FC<TagsProviderProps> = ({ children }) => {
  const [tags, setTags] = useState<TagSchema[]>([])
  const [tagsLoading, setTagsLoading] = useState(false)
  const projectCtx = useContext(ProjectsContext)
  const { project } = projectCtx

  const getTags = async (projectId: TagSchema['project_id']) => {
    setTagsLoading(true)
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (projectId === 'example') {
        setTags(mockTags)
        resolve({ data: { tags: mockTags } } as AxiosResponse)
        setTagsLoading(false)
      } else {
        axios
          .get('api/v1/tags', { params: { project_id: projectId } })
          .then((response) => {
            setTags(response.data.tags)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            setTagsLoading(false)
          })
      }
    })
  }

  const createTag = async (tag: TagSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (tag.project_id === 'example') {
        const maxId = tags.reduce(
          (max, tag) => (tag.id && tag.id > max ? tag.id : max),
          0,
        )
        const newTag = { ...tag, id: maxId + 1, model_type: 'Tag' }
        setTags([...tags, newTag])
        resolve({ data: { tags: newTag } } as AxiosResponse)
      } else {
        axios
          .post('api/v1/tags', tag)
          .then((response) => {
            setTags([...tags, response.data.tags])
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const updateTag = (tag: TagSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (tag.project_id === 'example') {
        const updatedTags = tags.map((t) =>
          t.id === tag.id ? { ...t, ...tag } : t,
        )
        setTags(updatedTags)
        resolve({ data: { tags: tag } } as AxiosResponse)
      } else {
        axios
          .put(`api/v1/tags/${tag.id}`, tag)
          .then((response) => {
            const updatedTag = response?.data?.tags

            const updatedTags = tags.map((c) => {
              if (c.id === updatedTag.id) {
                return updatedTag
              }
              return c
            })
            setTags(updatedTags)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const deleteTag = (id: TagSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project && project.id === 'example') {
        const updatedTags = tags.filter((c) => c.id !== id)
        setTags(updatedTags)
        resolve({ data: {} } as AxiosResponse)
      } else {
        axios
          .delete(`api/v1/tags/${id}`)
          .then((response) => {
            const updatedTags = tags.filter((c) => c.id !== id)
            setTags(updatedTags)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  return (
    <TagsContext.Provider
      value={{
        tags,
        tagsLoading,
        getTags,
        createTag,
        updateTag,
        deleteTag,
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}

// Custom hook to access the tags context
export default TagsContext
