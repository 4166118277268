import React, { useState } from 'react'
import { Box, Center, Divider, IconButton, Stack } from '@chakra-ui/react'
import { Droppable } from '@hello-pangea/dnd'
import { MdAdd } from 'react-icons/md'

interface NewBoardDropZoneProps {
  boardId: number | string
}

const NewBoardDropZone: React.FC<NewBoardDropZoneProps> = ({ boardId }) => {
  const [droppableId] = useState(`newBoard-${boardId}`)

  return (
    <Droppable droppableId={droppableId} type="note">
      {(provided, snapshot) => (
        <Box
          w={snapshot.isDraggingOver ? '24px' : '16px'}
          minH="calc(100vh - 128px)"
          my={4}
          borderRadius="2px"
          ref={provided.innerRef}
          {...provided.droppableProps}
          transition="width 0.3s ease-in-out"
        >
          <Stack>
            <Center>
              <IconButton
                aria-label="New Scene"
                icon={<MdAdd />}
                variant="outline"
                colorScheme="teal"
                maxH="24px"
                minW="24px"
                mt={2}
                borderRadius="4px"
                opacity={snapshot.isDraggingOver ? 1 : 0}
              />
            </Center>
            <Center>
              <Divider
                orientation="vertical"
                opacity={snapshot.isDraggingOver ? 1 : 0}
                minH="calc(100vh - 128px)"
              />
            </Center>
          </Stack>
        </Box>
      )}
    </Droppable>
  )
}

export default NewBoardDropZone
