import React, { ReactNode, createContext, useContext, useState } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'
import { mockCharacters } from './mocks/lotr'
import ProjectsContext from './projects-context'

export interface CharacterSchema {
  id: number | null
  name: string
  description?: string
  avatar_url?: string
  project_id: number | string | null
  model_type?: string
}

interface CharactersContextType {
  characters: CharacterSchema[]
  charactersLoading: boolean
  getCharacters: (
    projectId: CharacterSchema['project_id'],
  ) => Promise<AxiosResponse>
  createCharacter: (character: any) => Promise<AxiosResponse>
  updateCharacter: (character: CharacterSchema) => Promise<AxiosResponse>
  deleteCharacter: (id: CharacterSchema['id']) => Promise<AxiosResponse>
}

const CharactersContext = createContext<CharactersContextType>({
  characters: [],
  charactersLoading: false,
  getCharacters: async () => {
    throw new Error('getCharacters function not implemented')
  },
  createCharacter: async () => {
    throw new Error('createCharacter function not implemented')
  },
  updateCharacter: async () => {
    throw new Error('updateCharacter function not implemented')
  },
  deleteCharacter: async () => {
    throw new Error('deleteCharacter function not implemented')
  },
})

interface CharactersProviderProps {
  children?: ReactNode
}

export const CharactersProvider: React.FC<CharactersProviderProps> = ({
  children,
}) => {
  const [characters, setCharacters] = useState<CharacterSchema[]>([])
  const [charactersLoading, setCharactersLoading] = useState(false)
  const projectCtx = useContext(ProjectsContext)
  const { project } = projectCtx

  const getCharacters = async (projectId: CharacterSchema['project_id']) => {
    setCharactersLoading(true)
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (projectId === 'example') {
        setCharacters(mockCharacters)
        resolve({ data: { characters: mockCharacters } } as AxiosResponse)
        setCharactersLoading(false)
      } else {
        axios
          .get('api/v1/characters', { params: { project_id: projectId } })
          .then((response) => {
            setCharacters(response.data.characters)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            setCharactersLoading(false)
          })
      }
    })
  }

  const createCharacter = async (character: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (character.project_id === 'example') {
        const maxId = characters.reduce(
          (max, character) =>
            character.id && character.id > max ? character.id : max,
          0,
        )
        // If the character has an avatar, create a URL for it
        if (character.avatar && character.avatar !== 'purge') {
          character.avatar_url = URL.createObjectURL(character.avatar)
        }

        const newCharacter = {
          ...character,
          id: maxId + 1,
          model_type: 'Character',
        }
        setCharacters([...characters, newCharacter])
        resolve({ data: { characters: character } } as AxiosResponse)
      } else {
        const formData = new FormData()
        formData.append('character[name]', character.name)
        formData.append('character[description]', character.description)
        formData.append('character[project_id]', character.project_id)
        if (character.avatar) {
          formData.append(
            'character[avatar]',
            character.avatar,
            character.avatar.name,
          )
        }

        axios
          .post('api/v1/characters', formData)
          .then((response) => {
            setCharacters([...characters, response.data.characters])
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const updateCharacter = (character: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (character.project_id === 'example') {
        // Update the character in the local state
        const updatedCharacters = characters.map((c) => {
          if (c.id === character.id) {
            // If the character's avatar was updated
            if (character.avatar) {
              // Revoke the old avatar URL
              if (c.avatar_url) {
                URL.revokeObjectURL(c.avatar_url)
              }
              // Create a new avatar URL
              character.avatar_url =
                character.avatar !== 'purge'
                  ? URL.createObjectURL(character.avatar)
                  : null
            }

            return { ...c, ...character }
          } else {
            return c
          }
        })
        setCharacters(updatedCharacters)
        resolve({ data: { characters: character } } as AxiosResponse)
      } else {
        const formData = new FormData()
        formData.append('character[name]', character.name)
        formData.append('character[description]', character.description)
        formData.append('character[project_id]', character.project_id)
        if (character.avatar) {
          if (character.avatar === 'purge') {
            formData.append('character[avatar_purge]', 'purge')
          } else {
            formData.append(
              'character[avatar]',
              character.avatar,
              character.avatar.name,
            )
          }
        }
        axios
          .put(`api/v1/characters/${character.id}`, formData)
          .then((response) => {
            const updatedCharacter = response.data.characters
            const updatedCharacters = characters.map((c) =>
              c.id === updatedCharacter.id ? updatedCharacter : c,
            )

            setCharacters(updatedCharacters)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const deleteCharacter = (id: CharacterSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project && project.id === 'example') {
        const updatedCharacters = characters.filter((c) => c.id !== id)
        setCharacters(updatedCharacters)
        resolve({ data: {} } as AxiosResponse)
      } else {
        axios
          .delete(`api/v1/characters/${id}`)
          .then((response) => {
            const updatedCharacters = characters.filter((c) => c.id !== id)
            setCharacters(updatedCharacters)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  return (
    <CharactersContext.Provider
      value={{
        characters,
        charactersLoading,
        getCharacters,
        createCharacter,
        updateCharacter,
        deleteCharacter,
      }}
    >
      {children}
    </CharactersContext.Provider>
  )
}

export default CharactersContext
