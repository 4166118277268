import React, { ReactNode, createContext, useContext, useState } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'
import { NoteSchema } from './notes-context'
import { mockBoards } from './mocks/lotr'
import ProjectsContext from './projects-context'

// Define the shape of a board
export interface BoardSchema {
  id: number | null
  name: string
  position?: any
  project_id: number | string | null
  notes?: NoteSchema[]
  note_ids?: number[]
  model_type?: string
}

// Define the shape of the context
interface BoardsContextType {
  ideationBoard: BoardSchema | null
  setIdeationBoard: (board: BoardSchema) => void
  boards: BoardSchema[]
  setBoards: (boards: BoardSchema[]) => void
  getBoards: (projectId: BoardSchema['project_id']) => Promise<AxiosResponse>
  createBoard: (board: BoardSchema) => Promise<AxiosResponse>
  updateBoard: (
    board: BoardSchema,
    newPosition?: { before?: number | null; after?: number | null },
  ) => Promise<AxiosResponse>
  deleteBoard: (id: BoardSchema['id']) => Promise<AxiosResponse>
}

// Create the initial context
const BoardsContext = createContext<BoardsContextType>({
  ideationBoard: null,
  setIdeationBoard: () => {},
  boards: [],
  setBoards: () => {},
  getBoards: async () => {
    throw new Error('getBoard function not implemented')
  },
  createBoard: async () => {
    throw new Error('createBoard function not implemented')
  },
  updateBoard: async () => {
    throw new Error('updateBoard function not implemented')
  },
  deleteBoard: async () => {
    throw new Error('deleteBoard function not implemented')
  },
})

interface BoardsProviderProps {
  children?: ReactNode
}

// Create the provider component
export const BoardsProvider: React.FC<BoardsProviderProps> = ({ children }) => {
  const [ideationBoard, setIdeationBoard] =
    useState<BoardsContextType['ideationBoard']>(null)
  const [boards, setBoards] = useState<BoardsContextType['boards']>([])
  const projectCtx = useContext(ProjectsContext)
  const { project } = projectCtx

  const getBoards = async (projectId: BoardSchema['project_id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (projectId === 'example') {
        setBoards(mockBoards)
        resolve({ data: { boards: mockBoards } } as AxiosResponse)
      } else {
        axios
          .get('api/v1/boards', {
            params: { project_id: projectId },
          })
          .then((response) => {
            setBoards(response.data.boards)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const createBoard = async (board: BoardSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (board.project_id === 'example') {
        console.log('Creating board:', board)

        const maxId = boards.reduce(
          (max, board) => (board.id && board.id > max ? board.id : max),
          0,
        )
        const newBoard = {
          ...board,
          id: maxId + 1,
          notes: board.notes ?? [],
          note_ids: board.note_ids ?? [],
          model_type: 'Board',
        }
        const newBoards = [...boards]

        if (newBoard.position) {
          const afterBoardId = newBoard.position.after
          const afterBoardIndex = newBoards.findIndex(
            (b) => b.id === afterBoardId,
          )

          newBoards.splice(afterBoardIndex + 1, 0, newBoard)
        } else {
          newBoards.push(newBoard)
        }

        setBoards(newBoards)
        resolve({ data: { boards: newBoard } } as AxiosResponse)
      } else {
        axios
          .post('api/v1/boards', { board: board })
          .then((response) => {
            const newBoard = response.data.boards
            const newBoards = [...boards]

            newBoards.splice(newBoard.position - 1, 0, newBoard)

            setBoards(newBoards)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const updateBoard = (board: BoardSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (board.project_id === 'example') {
        setBoards((prevBoards) => {
          return prevBoards.map((b) =>
            b.id === board.id ? { ...b, ...board } : b,
          )
        })
        resolve({ data: { boards: board } } as AxiosResponse)
      } else {
        axios
          .put(`api/v1/boards/${board.id}`, { board: board })
          .then((response) => {
            setBoards((prevBoards) => {
              return prevBoards.map((b) =>
                b.id === board.id ? { ...b, ...response.data.boards } : b,
              )
            })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const deleteBoard = (id: BoardSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project && project.id === 'example') {
        const oldBoardNotes = boards.find((b) => b.id === id)?.notes
        if (oldBoardNotes && ideationBoard?.notes) {
          ideationBoard.notes.push(...oldBoardNotes)
        }
        const updatedBoards = boards.filter((c) => c.id !== id)
        setBoards(updatedBoards)
        resolve({ data: {} } as AxiosResponse)
      } else {
        axios
          .delete(`api/v1/boards/${id}`)
          .then((response) => {
            const oldBoardNotes = boards.find((b) => b.id === id)?.notes
            if (oldBoardNotes && ideationBoard?.notes) {
              ideationBoard.notes.push(...oldBoardNotes)
            }
            const updatedBoards = boards.filter((c) => c.id !== id)
            setBoards(updatedBoards)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  return (
    <BoardsContext.Provider
      value={{
        ideationBoard,
        setIdeationBoard,
        boards,
        setBoards,
        getBoards,
        createBoard,
        updateBoard,
        deleteBoard,
      }}
    >
      {children}
    </BoardsContext.Provider>
  )
}

// Custom hook to access the boards context
export default BoardsContext
