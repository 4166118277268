import React, { ReactNode, createContext, useContext, useState } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'
import { mockCategories } from './mocks/lotr'
import ProjectsContext from './projects-context'

// Define the shape of a category
export interface CategorySchema {
  id: number | null
  name: string
  color: string
  project_id: number | string | null
  model_type?: string
}

// Define the shape of the context
interface CategoriesContextType {
  categories: CategorySchema[]
  categoriesLoading: boolean
  getCategories: (
    projectId: CategorySchema['project_id'],
  ) => Promise<AxiosResponse>
  createCategory: (category: CategorySchema) => Promise<AxiosResponse>
  updateCategory: (category: CategorySchema) => Promise<AxiosResponse>
  deleteCategory: (id: CategorySchema['id']) => Promise<AxiosResponse>
}

// Create the initial context
const CategoriesContext = createContext<CategoriesContextType>({
  categories: [],
  categoriesLoading: false,
  getCategories: async () => {
    throw new Error('getCategory function not implemented')
  },
  createCategory: async () => {
    throw new Error('createCategory function not implemented')
  },
  updateCategory: async () => {
    throw new Error('updateCategory function not implemented')
  },
  deleteCategory: async () => {
    throw new Error('deleteCategory function not implemented')
  },
})

interface CategoriesProviderProps {
  children?: ReactNode
}

// Create the provider component
export const CategoriesProvider: React.FC<CategoriesProviderProps> = ({
  children,
}) => {
  const [categories, setCategories] = useState<CategorySchema[]>([])
  const [categoriesLoading, setCategoriesLoading] = useState(false)
  const projectCtx = useContext(ProjectsContext)
  const { project } = projectCtx

  const getCategories = async (projectId: CategorySchema['project_id']) => {
    setCategoriesLoading(true)
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (projectId === 'example') {
        setCategories(mockCategories)
        resolve({ data: { categories: mockCategories } } as AxiosResponse)
        setCategoriesLoading(false)
      } else {
        axios
          .get('api/v1/categories', { params: { project_id: projectId } })
          .then((response) => {
            setCategories(response.data.categories)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            setCategoriesLoading(false)
          })
      }
    })
  }

  const createCategory = async (category: CategorySchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (category.project_id === 'example') {
        const maxId = categories.reduce(
          (max, category) =>
            category.id && category.id > max ? category.id : max,
          0,
        )
        setCategories([
          ...categories,
          { ...category, id: maxId + 1, model_type: 'Category' },
        ])
        resolve({ data: { categories: category } } as AxiosResponse)
      } else {
        axios
          .post('api/v1/categories', { category: category })
          .then((response) => {
            setCategories([...categories, response.data.categories])
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const updateCategory = (category: CategorySchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (category.project_id === 'example') {
        const updatedCategories = categories.map((c) =>
          c.id === category.id ? { ...c, ...category } : c,
        )

        setCategories(updatedCategories)
        resolve({ data: { categories: category } } as AxiosResponse)
      } else {
        axios
          .put(`api/v1/categories/${category.id}`, { category: category })
          .then((response) => {
            const updatedCategory = response?.data?.categories

            const updatedCategories = categories.map((c) => {
              if (c.id === updatedCategory.id) {
                return updatedCategory
              }
              return c
            })
            setCategories(updatedCategories)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const deleteCategory = (id: CategorySchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (project && project.id === 'example') {
        const updatedCategories = categories.filter((c) => c.id !== id)
        setCategories(updatedCategories)
        resolve({ data: {} } as AxiosResponse)
      } else {
        axios
          .delete(`api/v1/categories/${id}`)
          .then((response) => {
            const updatedCategories = categories.filter((c) => c.id !== id)
            setCategories(updatedCategories)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        categoriesLoading,
        getCategories,
        createCategory,
        updateCategory,
        deleteCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  )
}

// Custom hook to access the categories context
export default CategoriesContext
